/**
 * @ignore for documentation
 */
export const environment = {
  apiUrl: 'https://my-stage.compassion.eu/api/com/',
  mainUrl: 'https://my-stage.compassion.eu/',
  production: false,
  apikey: 'yjtfghdfgsewrethn',
  features: {
    paymentsPage: true,
    correspondenceChanger: true,
    changeEmail: true,
    faqWidget: true,
    canDonate: true,
    changeContact: true,
    showTestimonials: true,
    loadPiwik: true,
    useDrafts: false,
    forcePasswordChange: false,
  },
};
