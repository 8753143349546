import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User } from '../../interfaces/user.interface';
import { CacheService } from '../cache.service';
import { CookiesService } from '../cookie.service';

/**
 * The auth service handles the authentication of users in the app.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  /**
   * Array with all the descriptions to unsub from whe the page leaves
   *
   * @private
   * @type {Subscription[]}
   * @memberof ChildDetailPage
   */
  private subscriptions: Subscription[] = [];

  /**
   * Saves the check if user is logged in as Subject
   *
   * @private
   * @memberof AuthService
   */
  private loggedInSubject = new BehaviorSubject<boolean>(false);

  /**
   * Creates an instance of AuthService.
   * @param {HttpClient} http
   * @memberof AuthService
   */
  constructor(
    private http: HttpClient,
    private router: Router,
    private cache: CacheService
  ) {
    this.startLoginChecker();
    this.loggedInSubject.next(this.tokenIsExpired());
  }

  /**
   * Return logged in Subject
   *
   * @returns {Observable<boolean>}
   * @memberof AuthService
   */
  getLoggedIn$(): Observable<boolean> {
    return this.loggedInSubject.asObservable();
  }

  /**
   * Login user and sets logged in subject
   *
   * @param {string} username
   * @param {string} password
   * @returns
   * @memberof AuthService
   */
  login(username: string, password: string) {
    // TODO: check for server response, only return if status is ok
    return this.http
      .post<User>(`${environment.apiUrl}loginLocal`, {
        username,
        password,
      })
      .pipe(tap((res) => this.setSession(res)));
  }

  /**
   * Sets the token in local storage
   *
   * @param {*} authResult
   * @memberof AuthService
   */
  public setSession(authResult) {
    this.loggedInSubject.next(true);
    this.cache.set(
      'forcePasswordChange',
      authResult.forcePasswordChange,
      'local'
    );
    const expiresAt = moment().add(authResult.expires, 'second');
    this.cache.set('id_token', authResult.token, 'local', {
      expires: expiresAt.valueOf(),
    });
  }

  /**
   * Logout user and remove token from local storage
   *
   * @memberof AuthService
   */
  logout() {
    this.clearLocalStorage();
    this.loggedInSubject.next(false);
  }

  /**
   * Removes id_token and from local storage
   *
   * @private
   * @memberof AuthService
   */
  private clearLocalStorage() {
    this.cache.clear();
    CookiesService.deleteCookie('__tawkuuid');
    CookiesService.deleteCookie('mycompassion');
  }

  /**
   * Starts checking if you are logged in every time the route changes
   *
   * @private
   * @memberof AuthService
   */
  private startLoginChecker() {
    const sub$ = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const loggedIn = this.tokenIsExpired();
        this.loggedInSubject.next(loggedIn);
        if (!loggedIn && this.cache.exists('id_token')) {
          this.clearLocalStorage();
        }
      });

    this.subscriptions.push(sub$);
  }

  /**
   * Gets token from local storage and converts it to a Moment object
   *
   * @private
   * @returns {moment.Moment}
   * @memberof AuthService
   */
  private tokenIsExpired(): boolean {
    return this.cache.exists('id_token', 'local');
  }

  /**
   * Unsubscribe from the observables
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
