import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { CacheService } from '../cache.service';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

/**
 * The auth guard checks if a user is logged in,
 * if true it will the user can access the route
 * if false the user will be redirected to the login page
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  /**
   * Creates an instance of AuthGuard.
   * @param {AuthService} authService
   * @param {Router} router
   * @memberof AuthGuard
   */
  constructor(
    private router: Router,
    private cache: CacheService,
    private authService: AuthService
  ) {}

  /**
   * Checks if a route can be activated
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns
   * @memberof AuthGuard
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const forcePasswordChange = this.cache.get('forcePasswordChange', 'local');

    if (this.tokenIsNotExpired() && state.url === '/profiel/change-password')
      return true;

    // FIXME: [DED-265] Remove Feature Toggle
    const featureToggle = environment.features.forcePasswordChange;
    if (
      featureToggle &&
      forcePasswordChange === true &&
      this.tokenIsNotExpired()
    ) {
      this.router.navigateByUrl('/profiel/change-password');
      return false;
    }

    if (this.tokenIsNotExpired()) {
      return true;
    }
    this.authService.logout();
    if (state.url !== '/profiel/change-password')
      this.cache.set('redirect-url', state.url, 'session');

    this.router.navigateByUrl('/login');
    return false;
  }

  private tokenIsNotExpired(): boolean {
    return this.cache.exists('id_token', 'local');
  }
}
