import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription, throwError } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-submit-mail-change',
  templateUrl: './submit-mail-change.page.html',
  styleUrls: ['./submit-mail-change.page.scss'],
})
export class SubmitMailChange implements OnInit, OnDestroy {
  /**
   * Array with all the descriptions to unsub from whe the page leaves
   *
   * @private
   * @type {Subscription[]}
   * @memberof ChangeEmailPage
   */
  private subscriptions: Subscription[] = [];

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private router: Router,
    private translateService: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit() {
    if (!environment.features.changeEmail) {
      this.router.navigateByUrl('/profiel');
      return;
    }

    const hashCheck$ = this.route.queryParams.pipe(
      shareReplay(1),
      switchMap((params: { hash: string }) => {
        const { hash } = params;
        if (!hash) {
          return throwError(undefined);
        }
        return this.userService.confirmMailChange(hash);
      })
    );

    this.subscriptions.push(
      hashCheck$.subscribe(
        () => {
          this.translateService
            .get('E-MAIL.changed.message')
            .subscribe((text: string) => {
              this.toastrService.success(text);
              this.router.navigateByUrl('/profiel');
            });
        },
        (error: HttpErrorResponse) => {
          let errorString:
            | 'E-MAIL.changed.error.no-request'
            | 'E-MAIL.changed.error.expired'
            | 'ERROR.badRequest' = 'ERROR.badRequest';
          if (error && error.status === 400) {
            // No change requested
            errorString = 'E-MAIL.changed.error.no-request';
          }

          if (error && error.status === 401) {
            // No request expired
            errorString = 'E-MAIL.changed.error.expired';
          }

          this.translateService.get(errorString).subscribe((text: string) => {
            this.toastrService.error(text);
            this.router.navigateByUrl('/profiel');
          });
        }
      )
    );
  }
}
